export const types = Object.freeze({
  KINDLY_LIBRARY: 'kindly-library',
  SET_LIBRARY_WIDTH: 'SET_LIBRARY_WIDTH',
});

export const systemDialogueCategories = Object.freeze({
  PRIVACY: 'PRIVACY',
  SUGGESTIONS: 'SUGGESTIONS',
  // FIXME(legacy-takeover): Make pat support both categories
  HANDOVER: 'TAKEOVER',
  ERRORS: 'ERRORS',
});

export const systemDialogueMessageTypes = Object.freeze({
  CANCEL: 'CANCEL',
  CLOSED: 'CLOSED',
  DELETE_CHAT: 'DELETE_CHAT',
  END: 'END',
  USER_LEFT: 'USER_LEFT',
  FALLBACK: 'FALLBACK',
  REPLY: 'REPLY',
  REQUEST: 'REQUEST',
  START: 'START',
  WAITING_FALLBACK: 'WAITING_FALLBACK',
  AGENT_ASSIGNED: 'AGENT_ASSIGNED',
});

export const BOTTOM_LEVEL = 'BOTTOM_LEVEL';

export type DialogueLabelColor = 'GRAY' | 'GREEN' | 'RED' | 'BLUE' | 'ORANGE';
type DialogueLabelColorHash = '#dbe0e6' | '#1cd300' | '#fa4d1e' | '#0069ff' | '#ffca3a';

export const DIALOGUE_LABEL_COLORS = new Map<DialogueLabelColor, DialogueLabelColorHash>([
  ['GRAY', '#dbe0e6'], // grayscale30
  ['GREEN', '#1cd300'],
  ['RED', '#fa4d1e'],
  ['BLUE', '#0069ff'],
  ['ORANGE', '#ffca3a'],
]);

export const DEFAULT_DIALOGUE_LABEL_COLOR = DIALOGUE_LABEL_COLORS.get('ORANGE');

export const CACHE_TYPES = Object.freeze({
  DIALOGUE: 'DialogueType',
  DIALOGUE_MOD: 'DialogueModType',
  SUGGESTION_DIALOGUE: 'SuggestionDialogueType',
});
