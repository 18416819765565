import React from 'react';

import { Chart, Info, Sparkles } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import InfoModal from 'frontend/components/InfoModal';
import Filters from 'frontend/components/NewFilters/Filters';
import { EmbeddedAnalytics } from 'frontend/features/Analytics/components';
import { LUZMO_DASHBOARDS } from 'frontend/features/Analytics/components/EmbeddedAnalytics/constants';
import { useModal } from 'frontend/features/Modals';
import { useTabTitle } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';

import styles from './Overview.scss';

const getGreeting = () => {
  const date = new Date();
  const hours = date.getHours();
  if (hours < 12) {
    return 'Good morning';
  }
  if (hours < 18) {
    return 'Good afternoon';
  }
  return 'Good evening';
};

const Overview = () => {
  useTabTitle('Insights - Overview');

  const [showInfoModal] = useModal(InfoModal);

  const me = useMe();

  return (
    <div className={styles.insightsOverviewWrapper}>
      <Filters
        path="INSIGHTS_OVERVIEW"
        className={styles.insightsFilter}
        canIncludeExclude={false}
        filters={['languageFilter', 'sourceFilter']}
        canAddMoreThanOne={false}
      />
      <div className={styles.insightsContainer}>
        <div className={styles.header}>
          <div className={styles.title}>
            {`${getGreeting()}, ${me.data?.me.profile.firstName || me.data?.me.profile.fullName || me.data?.me.username}`}
            <Icon color="orange" component={Sparkles} />
          </div>
          <div className={styles.titleDescription}>
            Welcome to insights dashboard! Here, you’ll get a quick overview on what users have been talking about
            lately.
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <div className={styles.contentTitle}>
              Insights summary{' '}
              <Icon
                component={Info}
                onClick={() =>
                  showInfoModal({
                    icon: Chart,
                    title: 'Insights summary',
                    text: (
                      <>
                        <p>Insights Summary analyzes the behavior of your users who interact with {`{_name}`}</p>
                        <p>
                          The analytics is made by AI and LLMs and provides an overview of the topics that are being
                          triggered by the users in the last 24 hours.
                        </p>
                      </>
                    ),
                  })
                }
              />
            </div>
            <div className={styles.contentDescription}>
              See recent topics your users have asked about the last 24 hours
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.contentContainer}>
              <div className={styles.contentGraph}>
                <EmbeddedAnalytics
                  shouldDisplayFilters={false}
                  key={LUZMO_DASHBOARDS.TOPICS.slug}
                  integrationLevel="bot"
                  dashboardId={LUZMO_DASHBOARDS.TOPICS.luzmoId}
                  dashboardSlug={LUZMO_DASHBOARDS.TOPICS.slug}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
