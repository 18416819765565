import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { BotDocument } from 'frontend/api/generated';
import { Clock, Exclamation } from 'frontend/assets/icons';
import useMe from 'frontend/hooks/useMe';

import styles from './EventMessagePreview.scss';
import { Icon } from '../Icon/Icon';

interface EventMessagePreviewProps {
  eventMessage: {
    text: string;
  };
  messageType: string;
}

const messageTypeToIcon = {
  REQUEST: Exclamation,
  CLOSED: Clock,
  WAITING_FALLBACK: Clock,
  START: Clock,
  END: Clock,
};

export default function EventMessagePreview({ eventMessage, messageType }: EventMessagePreviewProps) {
  const { data } = useMe();
  const me = data?.me;
  const aliasName = me?.profile?.alias || "Agent's name";

  const { botId } = useParams();

  const { data: botData } = useQuery(BotDocument, { variables: { botId: botId as string } });
  const botName = botData?.bot?.name || "[Bot's name]";
  const previewEventMessage = eventMessage.text.replace(/@agent_name/g, aliasName).replace(/@bot_name/g, botName);

  const IconComponent = messageTypeToIcon[messageType];
  return (
    <span className={styles.previewEventMessage}>
      {IconComponent && <Icon component={IconComponent} className={styles.icon} />}
      {previewEventMessage}
    </span>
  );
}
