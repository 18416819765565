import { orderBy } from 'lodash';
import { useMemo } from 'react';
import type { FieldRenderProps } from 'react-final-form';

import { useCurrentLanguage } from 'frontend/hooks';
import type { PartialDialogue } from 'frontend/types/dialogue';

import DialogueOption from './DialogueOption';
import styles from './SelectDialogue.scss';
import InputErrorWrapper from '../InputErrorWrapper';
import type { ErrorPosition } from '../InputErrorWrapper/InputErrorWrapper';
import Loader from '../Loader';
import SelectWithSearch from '../SelectWithSearch';

// look for matches in the first 1000*0.3=300 characters in the title; see https://fusejs.io/api/options.html#distance
const FUSE_CONFIG = { distance: 1000, keys: ['name'], threshold: 0.3 };

type Option = {
  name: string;
  value: string;
  highlighted?: boolean;
  tooltip?: string | undefined;
  isActive?: boolean;
  activeOnly?: boolean;
};

function dialogueToOption({
  currentBaseLanguage,
  selectedLanguage,
  highlightedDialogueId,
  activeOnly,
}: {
  currentBaseLanguage: string;
  selectedLanguage: string;
  highlightedDialogueId?: string;
  activeOnly: boolean;
}) {
  return ({ id, title, path, isActive }: PartialDialogue): Option => {
    const name = title[currentBaseLanguage] ?? title.default;
    const highlighted = id === highlightedDialogueId;

    return {
      name,
      value: id,
      highlighted,
      tooltip: path?.[currentBaseLanguage],
      isActive: isActive?.[selectedLanguage],
      activeOnly,
    };
  };
}

function lowercaseName({ name }: Option): string {
  return name.toLowerCase();
}

interface SelectDialogueProps extends FieldRenderProps<string> {
  placeholder?: string;
  highlightedDialogueId?: string;
  loading?: boolean;
  activeOnly?: boolean;
  allowEmpty?: boolean;
  dialogues: PartialDialogue[];
  errorPosition?: ErrorPosition;
  /** If true, the select dropdown will be half the size. */
  shortDropdown?: boolean;
}

const SelectDialogue = ({
  input,
  dialogues,
  placeholder,
  loading,
  highlightedDialogueId,
  activeOnly = false,
  allowEmpty = false,
  label,
  className,
  meta,
  errorPosition,
  shortDropdown,
}: SelectDialogueProps) => {
  const [{ selectedLanguage, currentLanguage: currentBaseLanguage }] = useCurrentLanguage();

  const hasError = Boolean(meta?.submitFailed && (meta?.submitError || meta?.error));
  const errorMessage = meta?.submitError || meta?.error || '';

  const options: Option[] = useMemo(() => {
    if (loading || !dialogues) {
      return [];
    }

    const filtered = activeOnly ? dialogues.filter(({ isActive }) => isActive?.[selectedLanguage]) : dialogues;
    const asOptions = filtered.map(
      dialogueToOption({ currentBaseLanguage, selectedLanguage, highlightedDialogueId, activeOnly }),
    );
    return [...(allowEmpty ? [{ name: 'None', value: '' }] : []), ...orderBy(asOptions, lowercaseName)];
  }, [activeOnly, allowEmpty, highlightedDialogueId, selectedLanguage, currentBaseLanguage, dialogues, loading]);

  return (
    <>
      {label && (
        <div className={styles.labelWrapper}>
          <label htmlFor={input.name}>{label}</label>
        </div>
      )}
      {loading ? (
        <div className={styles.loaderWrapper}>
          <Loader size="small" />
        </div>
      ) : (
        <InputErrorWrapper
          hasError={hasError}
          className={className}
          errorMessageClassName={styles.errorMessage}
          displayError={errorMessage}
          errorPosition={errorPosition}
        >
          <SelectWithSearch
            input={input}
            options={options}
            hasError={hasError}
            placeholder={placeholder}
            fuse={FUSE_CONFIG}
            renderOption={DialogueOption}
            meta={{}}
            shortDropdown={shortDropdown}
          />
        </InputErrorWrapper>
      )}
    </>
  );
};

export default SelectDialogue;
