/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Calendar,
  Channel,
  Directions,
  Email,
  EmojiPlus,
  Form,
  Handover,
  Labels as LabelsIcon,
  Language,
  SpeechBubble,
} from 'frontend/assets/icons';
import SourcesFilter from 'frontend/components/NewFilters/components/SourcesFilter/SourcesFilter';
import { BUTTON_CONTACT_DETAILS_TYPES } from 'frontend/features/Build/constants';
import { capitalize } from 'frontend/utils';

import ButtonTitle, { type FilterKeyType } from '../components/ButtonTitle/ButtonTitle';
import ContactDetailsFilter from '../components/ContactDetailsFilter/ContactDetailsFilter';
import DateFilter from '../components/DateFilter/DateFilter';
import DialoguesFilter from '../components/DialoguesFilter/DialoguesFilter';
import FeedbackFilter from '../components/FeedbackFilter/FeedbackFilter';
import FormFilter from '../components/FormFilter/FormFilter';
import HandoverFilter from '../components/HandoverFilter/HandoverFilter';
import LabelsFilter from '../components/LabelsFilter/LabelsFilter';
import LanguageFilter from '../components/LanguageFilter/LanguageFilter';
import ReplyTypesFilter from '../components/ReplyTypesFilter/ReplyTypesFilter';

export const EXCLUDED_FILTERS_PREFIX = 'excl';

export const CONTACT_DETAILS_STATUS = Object.freeze({
  requested: 'requested',
  done: 'done',
});

export type FilterType = {
  activeTitle: (filterKey: FilterKeyType) => JSX.Element;
  text: string;
  canExclude?: boolean;
  filterKey: FilterKeyType;
  suffix?: string;
  icon: React.ComponentType<any>; // Assuming icons are React components
  component: (filterKey: string, shouldSubmitOnToggle: boolean) => JSX.Element;
  closeMenuOnSelection: boolean;
};

const MAIN_FILTERS_LIST: FilterType[] = [
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Date',
    canExclude: true,
    filterKey: 'dateFilter',
    icon: Calendar,
    component: (filterKey, shouldSubmitOnToggle) => (
      <DateFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Labels',
    canExclude: true,
    filterKey: 'labelFilter',
    suffix: 'label',
    icon: LabelsIcon,
    component: (filterKey, shouldSubmitOnToggle) => (
      <LabelsFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Reply type',
    canExclude: true,
    filterKey: 'replyTypeFilter',
    suffix: 'type',
    icon: Directions,
    component: (filterKey, shouldSubmitOnToggle) => (
      <ReplyTypesFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  // {
  //   activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
  //   text: 'Agent',
  //   canExclude: true,
  //   filterKey: 'ticketAgentFilter',
  //   icon: Agent,
  //   component: (filterKey) => <AgentsFilter filterKey={filterKey} />,
  //   closeMenuOnSelection: false,
  // },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Channel',
    canExclude: true,
    filterKey: 'sourceFilter',
    suffix: 'channel',
    icon: Channel,
    component: (filterKey, shouldSubmitOnToggle) => (
      <SourcesFilter sourceKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Handover',
    canExclude: true,
    filterKey: 'handoverStatusFilter',
    suffix: 'status',
    icon: Handover,
    component: (filterKey, shouldSubmitOnToggle) => (
      <HandoverFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Language',
    filterKey: 'languageFilter',
    suffix: 'language',
    canExclude: true,
    icon: Language,
    component: (filterKey, shouldSubmitOnToggle) => (
      <LanguageFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Contact info',
    canExclude: true,
    filterKey: 'contactDetailsStatusFilter',
    icon: Email,
    component: (filterKey, shouldSubmitOnToggle) => (
      <ContactDetailsFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Feedback',
    canExclude: true,
    filterKey: 'feedbackFilter',
    icon: EmojiPlus,
    component: (filterKey, shouldSubmitOnToggle) => (
      <FeedbackFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Form',
    canExclude: true,
    filterKey: 'formFilter',
    icon: Form,
    component: (filterKey, shouldSubmitOnToggle) => (
      <FormFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
  {
    activeTitle: (filterKey) => <ButtonTitle filterKey={filterKey} />,
    text: 'Dialogues',
    canExclude: true,
    filterKey: 'dialogueFilter',
    icon: SpeechBubble,
    component: (filterKey, shouldSubmitOnToggle) => (
      <DialoguesFilter filterKey={filterKey} shouldSubmitOnToggle={shouldSubmitOnToggle} />
    ),
    closeMenuOnSelection: false,
  },
];

export const FILTERS_LIST = [
  ...MAIN_FILTERS_LIST,
  ...MAIN_FILTERS_LIST.filter(({ canExclude }) => canExclude).map((filter) => ({
    ...filter,
    filterKey: `${EXCLUDED_FILTERS_PREFIX}${capitalize(filter.filterKey)}` as FilterKeyType,
  })),
];

export const contactDetailsStatusData = [
  {
    label: 'Requested contact by email',
    value: BUTTON_CONTACT_DETAILS_TYPES.EMAIL,
  },
  {
    label: 'Requested contact by phone',
    value: BUTTON_CONTACT_DETAILS_TYPES.PHONE,
  },
  { label: 'Was contacted', value: CONTACT_DETAILS_STATUS.done },
];

/* `value`s must be the same as they come from `pat` */
export const replyTypes = [
  { label: 'Fallback', value: 'FALLBACK' },
  { label: 'Sample', value: 'SAMPLE_MATCH' },
  { label: 'Intent', value: 'INTENT_MATCH' },
  { label: 'Intent (Simplified)', value: 'INTENT_MATCH_SIMPLIFIED' },
  { label: 'AI smalltalk', value: 'AI_SMALLTALK' },
  { label: 'Keyword (Exact)', value: 'KEYWORD_EXACT_MATCH' },
  { label: 'Keyword (Mention)', value: 'KEYWORD_MENTION' },
  { label: 'Pattern', value: 'PATTERN_MATCH' },
  { label: 'AI generated', value: 'MIMIR' },
  { label: 'Suggestion', value: 'FALLBACK_SUGGESTION' },
];

export const formStateData = Object.freeze([
  {
    label: 'Submitted forms',
    value: 'SUBMITTED',
  },
  {
    label: 'Unanswered forms',
    value: 'UNANSWERED',
  },
  {
    label: 'Canceled forms',
    value: 'CANCELED',
  },
]);

export const handoverStatusData = Object.freeze([
  {
    label: 'Requested handover',
    value: 'has_requested_takeover',
  },
  {
    label: 'Handover in progress',
    value: 'taken_over',
  },
  {
    label: 'Completed handover',
    value: 'completed_takeover',
  },
]);

export const initialFilters = {
  sourceFilter: [],
  handoverStatusFilter: [],
  labelFilter: [],
  languageFilter: [],
  ticketAgentFilter: '',
  botFilter: [],
  dateFilter: [],
  replyTypeFilter: [],
};

export const feedbackData: { label: string; value: string | { label: string; value: string }[] }[] = [
  {
    label: 'Form',
    value: [
      {
        label: 'Handover form',
        value: 'handover_conversation',
      },
      {
        label: 'Bot form',
        value: 'bot_conversation',
      },
    ],
  },
  {
    label: 'Rate scaling',
    value: [
      {
        label: 'Positive',
        value: 'positive',
      },
      {
        label: 'Neutral',
        value: 'neutral',
      },
      {
        label: 'Negative',
        value: 'negative',
      },
    ],
  },
  {
    label: 'Predefined buttons',
    value: [
      {
        label: 'User has interacted',
        value: 'user_has_interacted',
      },
      {
        label: 'User has not interacted',
        value: 'user_has_not_interacted',
      },
    ],
  },
  {
    label: 'Freeform text',
    value: [
      {
        label: 'User left a comment',
        value: 'freeform_text',
      },
    ],
  },
];
